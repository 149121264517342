import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
// import AppTest from './AppTest';
import ErrorPage from './ErrorPage'
import CompletePage from './CompletePage'
import EjectPage from './EjectPage';
import RegistrationPage from './RegistrationPage';
import VerificationPage from './VerificationPage';
import CancelPage from './CancelPage';
import EventFullPage from './EventFullPage';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

const AppRoutes = React.memo((props) => {
    console.log('render routes', props)
    const { t } = useTranslation('translation');
    const location = useLocation();
    const state = location?.state || {}
    const {error = t('error_not_found'), message = t('error_page_not_found'), logo = "PFM"} = state;

    return (
        // <div style={{
        //     width: '100vw',
        //     height: '100vh'
        // }}>
        <SnackbarProvider maxSnack={10} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} autoHideDuration={6000}>
            <Routes>
                <Route exact path='/session/:sessionId' Component={App} />
                <Route exact path='/session/:sessionId/:requestId' Component={App} />
                <Route exact path='/techcheck/:sessionId' Component={App} />
                <Route exact path='/register/:sessionId' Component={RegistrationPage} />
                <Route exact path='/verify/:guestId' Component={VerificationPage} />
                <Route exact path='/cancel/:guestId' Component={CancelPage} />
                <Route exact path='/complete' Component={CompletePage} />
                <Route exact path='/eject' Component={EjectPage} />
                <Route exact path='/full' Component={EventFullPage} />
                {/* <Route exact path='/error' Component={ErrorPage} /> */}
                <Route path='/error' element={<ErrorPage error={error} message={message} logo={logo} />} />
                <Route exact path='/' element={<Navigate to='/error' />} />
            </Routes>
        </SnackbarProvider>
        // </div>
    )
})

export default AppRoutes;