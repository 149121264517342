import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import queryString from 'query-string';
import LoadingPage from './LoadingPage';
import config from './config.json'
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const PREFIX = 'VerificationPage';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    spacing: `${PREFIX}-spacing`,
    select: `${PREFIX}-select`,
    loading: `${PREFIX}-loading`,
    blank: `${PREFIX}-blank`,
    submit: `${PREFIX}-submit`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    buttonBox: `${PREFIX}-buttonBox`,
    button: `${PREFIX}-button`
};

const Root = styled('div')({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    // root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     height: '100%',
    //     width: '100%',
    //     // background: '#222'
    //     background: `url(${background})`,
    //     backgroundSize: 'cover'
    // },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '300px',
        maxWidth: '800px'
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    [`& .${classes.spacing}`]: {
        width: '100%',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    [`& .${classes.select}`]: {
        margin: '0.25rem 0 0.25rem 0',
        backgroundColor: 'white'
    },
    [`& .${classes.loading}`]: {
        margin: '0.25rem 0 0.25rem 0',
        // width: 'calc(37px + 1.1876em)',
        // height: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.blank}`]: {
        marginTop: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.submit}`]: {
        position: 'relative',
        alignSelf: 'flex-end',
        marginTop: '1rem'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    [`& .${classes.buttonBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.button}`]: {
        margin: '0 6px 0 6px'
    }
});

const verifyGuestPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/verify/${guestId}`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const startTechCheckPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/tech-check/${guestId}/start`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const VerificationPage = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search, state } = location;
    const { guestId } = useParams();

    const [loading, setLoading] = useState(true)
    const [waiting, setWaiting] = useState(false)
    const [sessionData, setSessionData] = useState()

    const { t } = useTranslation('verification_page');

    // const token = useRef()

    const { id: jwt } = queryString.parse(search);
    const id = jwt || state?.id

    useEffect(() => {
        if (!guestId || !id) {
            navigate('/')
        } else {
            verifyGuestPromise(guestId, id).then((res) => {
                console.log(res)
                if (res.state !== "Qualified") {
                    startTechCheckPromise(res.id, id).then((res) => {
                        navigate(`/techcheck/${res.id}`, { state: { id: id } })
                    }).catch((e) => {
                        console.log(e)
                        navigate(`/register/${res.sessionId}?${res.externalRecruitId ? `qid=${res.externalRecruitId}&` : ''}rid=${res.registrationId}`, { state: { force: true } })
                    })
                } else {
                    setSessionData(res)
                    // token.current = res.token
                    setLoading(false)
                }
            }).catch((e) => {
                console.log(e)
                navigate('/')
            })
        }
    }, [navigate, guestId, id])

    const handleStart = useCallback(() => {
        startTechCheckPromise(sessionData.id, id).then((res) => {
            navigate(`/techcheck/${res.id}`, { state: { id: id } })
        }).catch((e) => {
            console.log(e)
        })
    }, [navigate, id, sessionData])

    const handleWait = useCallback(() => {
        setWaiting(true)
    }, [])

    return (
        <Root>
            <div className={classes.root}>
                {loading && <LoadingPage />}
                {!(loading) && <BrandedPage team={sessionData.team}>
                    <SessionInfoBanner videoTitle={sessionData.videoTitle} dateLabel={t('Check-In Time')} sessionDate={sessionData.guestCheckInTime} />
                    <div className={classes.container}>
                        {waiting ? <>
                            <div className={classes.titleBox}>
                                <Typography variant="h4">{t('bummer')}</Typography>
                                <br />
                                <Typography variant="h6">{t('come_back_soon')}</Typography>
                            </div>
                        </> : <>
                            <div className={classes.titleBox}>
                                <Typography variant="h4">{sessionData.emailVerified ? t('youre_registered') : t('youre_verified')}</Typography>
                                <br />
                                <Typography variant="h6">{t('last_step')}</Typography>
                            </div>
                            <div className={classes.buttonBox}>
                                <Button className={classes.button} color='primary' variant='contained' onClick={handleStart}>{t('start_tech_check')}</Button>
                                <Button className={classes.button} color='primary' variant='contained' onClick={handleWait}>{t('complete_later')}</Button>
                            </div>
                        </>}
                    </div>
                </BrandedPage>}
            </div>
        </Root>
    );
})

export default VerificationPage;
