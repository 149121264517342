import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import queryString from 'query-string';
import LoadingPage from './LoadingPage';
import config from './config.json'
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const PREFIX = 'CancelPage';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    titleBox: `${PREFIX}-titleBox`,
    container: `${PREFIX}-container`
};

const Root = styled('div')({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
});

const cancelGuestPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/cancel/${guestId}`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const CancelPage = React.memo(() => {
    const navigate = useNavigate()
    const location = useLocation();
    const { search } = location;
    const { guestId } = useParams();

    const [loading, setLoading] = useState(true)
    const [sessionData, setSessionData] = useState()


    const { id } = queryString.parse(search);
    const { t } = useTranslation('self_tech_check_step_6');

    useEffect(() => {
        if (!guestId || !id) {
            navigate('/')
        } else {
            cancelGuestPromise(guestId, id).then((res) => {
                console.log(res)
                setSessionData(res)
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                navigate('/')
            })
        }
    }, [navigate, guestId, id])

    return (
        <Root className={classes.fullscreen}>
            <div className={classes.root}>
                {loading && <LoadingPage />}
                {!(loading) && <BrandedPage team={sessionData.team}>
                    <SessionInfoBanner videoTitle={sessionData.videoTitle} dateLabel={t('Check-In Time')} sessionDate={sessionData.guestCheckInTime} />
                    <div className={classes.container}>
                        <div className={classes.titleBox}>
                            <Typography variant="h4">{t('registration_cancelled')}</Typography>
                            <br />
                            <Typography variant="h6">{t('sorry_youre_unable')}</Typography>
                        </div>
                    </div>
                </BrandedPage>}
            </div>
        </Root>
    );
})

export default CancelPage;
